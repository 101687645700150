


/* Z-INDEX */
	.formError { z-index: 990; }
	.formError .formErrorContent { z-index: 991; }
	.formError .formErrorArrow { z-index: 996; }

	.ui-dialog .formError { z-index: 5000; }
	.ui-dialog .formError .formErrorContent { z-index: 5001; }
	.ui-dialog .formError .formErrorArrow { z-index: 5006; }




.inputContainer {
	position: relative;
	float: left;
}

.formError {
	position: absolute;
	top: 300px;
	left: 300px;
	display: block;
	cursor: pointer;
	text-align: left;
}

.formError.inline {
	position: relative;
	top: 0;
	left: 0;
	display: inline-block;
}

.ajaxSubmit {
	padding: 20px;
	background: #55ea55;
	border: 1px solid #999;
	display: none;
}

.formError .formErrorContent {
	width: 100%;
	min-width : 190px;
	background: #ee0101;
	position:relative;
	color: #fff;
	font-family: tahoma;
	font-size: 11px;
	border: 2px solid #ddd;
	box-shadow: 0 0 6px #000;
	-moz-box-shadow: 0 0 6px #000;
	-webkit-box-shadow: 0 0 6px #000;
	-o-box-shadow: 0 0 6px #000;
	padding: 4px 10px 4px 10px;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	-o-border-radius: 6px;
}

.formError.inline .formErrorContent {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	border: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-o-border-radius: 0;
}

.greenPopup .formErrorContent {
	background: #33be40;
}

.blackPopup .formErrorContent {
	background: #393939;
	color: #FFF;
}

.yellowPopup .formErrorContent
{
	background: #F6DC1C;
	color: #404040;
}

.formError .formErrorArrow {
	width: 15px;
	margin: -2px 0 0 13px;
	position:relative;
}
body[dir='rtl'] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
	margin: -2px 13px 0 0;
}

.formError .formErrorArrowBottom {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	margin: 0px 0 0 12px;
	top:2px;
}

.formError a
{
	color: #ffffff;
	text-decoration:underline
}

.formError .formErrorArrow div {
	border-left: 2px solid #ddd;
	border-right: 2px solid #ddd;
	box-shadow: 0 2px 3px #444;
	-moz-box-shadow: 0 2px 3px #444;
	-webkit-box-shadow: 0 2px 3px #444;
	-o-box-shadow: 0 2px 3px #444;
	font-size: 0px;
	height: 1px;
	background: #ee0101;
	margin: 0 auto;
	line-height: 0;
	font-size: 0;
	display: block;
}

.formError .formErrorArrowBottom div {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
}

.greenPopup .formErrorArrow div {
	background: #33be40;
}

.blackPopup .formErrorArrow div {
	background: #393939;
	color: #FFF;
}

.yellowPopup .formErrorArrow div {
	background: #F6DC1C;
	color: #FFF;
}

.formError .formErrorArrow .line10 {
	width: 15px;
	border: none;
}

.formError .formErrorArrow .line9 {
	width: 13px;
	border: none;
}

.formError .formErrorArrow .line8 {
	width: 11px;
}

.formError .formErrorArrow .line7 {
	width: 9px;
}

.formError .formErrorArrow .line6 {
	width: 7px;
}

.formError .formErrorArrow .line5 {
	width: 5px;
}

.formError .formErrorArrow .line4 {
	width: 3px;
}

.formError .formErrorArrow .line3 {
	width: 1px;
	border-left: 2px solid #ddd;
	border-right: 2px solid #ddd;
	border-bottom: 0 solid #ddd;
}

.formError .formErrorArrow .line2 {
	width: 3px;
	border: none;
	background: #ddd;
}

.formError .formErrorArrow .line1 {
	width: 1px;
	border: none;
	background: #ddd;
}
