.inlineNotification
{
	margin-left: 5px;
	height: 16px;
	width: 16px;
	float: left;
	vertical-align: top;
}

.inlineNotificationLoading
{
	cursor: auto;
}

.inlineNotificationSuccess
{
	cursor: auto;
}

.inlineNotificationWarning
{
	cursor: pointer;
}

.inlineNotificationError
{
	cursor: pointer;
}


.inlineNotificationImage
{
	width: 16px;
	height: 16px;
	vertical-align: middle;
}


.inlineNotificationModal
{
	z-index: 4550;
	line-height: 13px;
}

.inlineNotificationModalLoading .formErrorContent
{
	background-color: #393939;
	color:            #FFFFFF;
}

.inlineNotificationModalLoading .formErrorArrow > div
{
	background: none repeat scroll 0 0 #393939;
}

.inlineNotificationModalSuccess .formErrorContent
{
	background-color: #33BE40;
	color:            #FFFFFF;
}

.inlineNotificationModalSuccess .formErrorArrow > div
{
	background: none repeat scroll 0 0 #33BE40;
}

.inlineNotificationModalWarning .formErrorContent
{
	background-color: #F6DC1C;
	color:            #5E5E5E;
}

.inlineNotificationModalWarning .formErrorArrow > div
{
	background: none repeat scroll 0 0 #F6DC1C;
}

.inlineNotificationModalError .formErrorContent
{
	background-color: #EE0101;
	color:            #FFFFFF;
}

.inlineNotificationModalError .formErrorArrow div
{
	background: none repeat scroll 0 0 #EE0101;
}
