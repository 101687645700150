/*
The following file is the default style sheet for the platform
*/

div.autosize { display: table; width: 1px; }
div.autosize > div { display: table-cell; }

html, body
{
	padding: 0pt;
	margin: 0pt;
	width: 100%;
	height: 100%;
	font-family:Arial, bitstream vera sans, Tahoma, Verdana, sans-serif;
	background-color: #D7D7D7;
}

/*This is required for doctype html, so there are no spaces underneath images*/
img { vertical-align: bottom }

a{cursor:pointer}
button{cursor:pointer}

/*Remove dotted border on focused buttons*/
:focus {outline:none;}
::-moz-focus-inner {border:0;}

h1
{
	font-size: 20px;
	color: black;
}

h2
{
	font-size:12px;
	color: darkgray;
}

form
{
	padding:0px;
	margin-bottom:0px;
	margin-top:0px;
}

template
{
	display: none !important;
}

.notificationBullet
{
	margin-left:-10px;
}

.mainDiv
{
	background-color: #d0d0d0;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAMgCAIAAABtb5/QAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAADJJREFUSMdj+P//PxMDA8MoHsVDAqOnV2Q+sXLUMGPU7pEdJqN4FI/iUTyKR/EoJhsDAE4hla91iRE4AAAAAElFTkSuQmCC);
	background-repeat:repeat-x;
	margin: 0px;
	height: auto !important;
	height: 100%;
	min-height: 100%;
}



.contentDiv
{
	border-left: 1px solid #797979;
	border-right: 1px solid #797979;
	border-top: 5px solid #797979;
	border-bottom: 1px solid #797979;
	background-color:#FFFFFF;
	margin-left: 32px;
	margin-right: 32px;
	padding: 12px;
	min-height:400px;
	height:auto !important;
}

.bottomCopyright
{
	font-family:Arial, bitstream vera sans, sans-serif;
	font-size:11px;
	color:#949494;
	text-decoration:none;
	padding-right:30px;
	padding-top:5px;
}

.footerCopyRight
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:12px;
	color:#949494;
}

.a4 a
{
	text-decoration:none;
}

.loggedInContent
{
	font-family:Arial, bitstream vera sans, sans-serif;
	font-size:11px;
	color:#aeaeae;
	text-decoration:none;
}

.secondaryHeading
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:16pt;
	font-weight:bold;
	color:#000000;
	padding-bottom: 3px;
	padding-top: 3px;
	overflow-wrap: break-word;
}

.thirdHeading
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:14pt;
	font-weight:bold;
	color:#000000;
	padding-bottom: 3px;
	padding-top: 3px;
}

.tabSubHeading
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:12pt;
	font-weight:bold;
	color:#000000;
	padding-bottom: 3px;
	padding-top: 3px;
}


.warningContent
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:9pt;
	font-weight:bold;
	color:#000000;
	padding-bottom: 3px;
	padding-top: 3px;
}

.secondaryHeadingContent
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	color:#797979;
	font-size:8pt;
	margin-left: 1px;
	margin-bottom: 10px;
}

.pageErrorPrimaryHeading
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	color:#000000;
	font-size:14pt;
	margin-left: 1px;
	font-weight:bold;
}

.pageErrorSecondaryHeading
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	color:#000000;
	font-size:9pt;
	margin-left: 1px;
	font-weight:bold;
}

.pageErrorContent
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	color:#797979;
	font-size:8pt;
	margin-left: 1px;
}

.pageErrorContainer
{
	border:1px solid #000000;
	margin-top:2px;
	padding:10px;
	overflow:inherit;
}

.pageErrorBullets
{
	vertical-align:middle;
	padding-right:20px;
}

.mandatorySymbol
{
	color:#0000FF;
	font-size:11px;
	padding-right:2px;
}

input.text
{
	position:relative;
	background-color:#FFFFFF;
	text-align: left ;
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size: 11px;
	color:#949494;
	font-style:normal;
	font-weight:normal;
	text-decoration:none;
	width:450px;
	height:20px;
	border: 1px inset #797979;

}

textarea
{
	font-size:  12px;
	padding: 0px;
}

.groupButton
{
	width: 98px;
	height: 29px;
}

.soloButton
{
	width   : 103px;
	padding : 5px;
	float   : left;
}

.browseButton
{
	width   : 103px;
	height  : 25px;
	display : inline-block;
	float   : left;
}

.enabledButton
{
	background-image: url(data:image/gif;base64,R0lGODlhAQAdAMQAAPH3/+jz//b5//T4//D3/+31//v8//n7/+r0/+/3//P4/+z1//j7/+nz//X5//r8//3///L3/+v0/+fy/+72/+bx//f6//z+/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAABAB0AAAUYIHRdxnMwliU4gxIBREJRxSIhTTBNVRUCADs=);
	background-repeat:repeat-x;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	font-family: Arial, bitstream vera sans,Tahoma;
	font-size: 11px;
	color: #0033CC;
	text-align: center;
	font-weight:bold;
	border: 1px solid #d7d7d7;
	cursor: pointer;
}

.warningButton.enabledButton
{
	width: auto;
	height: 29px;
	min-width: 98px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAdCAIAAADkY5E+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3gcIBTokjBXbQwAAACNJREFUCNdj+M/AwPSXgYHpNwMD0x8o/o3GRxdDp9HFCIgDAKDLHLx6pOdzAAAAAElFTkSuQmCC);
	background-repeat:repeat-x;
	font-family: Arial, bitstream vera sans,Tahoma;
	font-size: 11px;
	color: #FFFFFF;
	border: 1px solid #FF0000;
	text-align: center;
	font-weight:bold;
	cursor: pointer;
}

.disabledButton
{
	-moz-border-radius    : 5px;
	-webkit-border-radius : 5px;
	border-radius         : 5px;
	font-family           : Arial, bitstream vera sans,Tahoma;
	font-size             : 11px;
	background-color      :#F2F2F2;
	color                 : #AEAEAE;
	text-align            : center;
	font-weight           : bold;
	border                : 1px solid #d7d7d7;
	cursor                : default;
	pointer-events        : none;
}

.enabledButton:focus
{
	border: 1px dashed #b7b7b7;
}

.doRunSendButton
{
	margin-left: 5px;
}

.errorTable
{
	font-size:8pt;
	color: #797979;
}

.errorTableMain
{
	font-weight:bold;
}

.errorTableHeading
{
	font-size:10pt;
	font-weight:bold;
}

/*
This sets the details for the big spinner that appears in the middle of the page
*/
.spinner
{
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -50px; /* half width of the spinner gif */
	margin-top: -50px; /* half height of the spinner gif */
	text-align:center;
	overflow: auto;
	width: 100px; /* width of the spinner gif */
	height: 102px; /*hight of the spinner gif +2px to fix IE8 issue */
}

.errorHeading
{
	color : #ff0000;
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:14pt;
	font-weight:bold;
}

.errorContent
{
	color : #0000ff;
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size: 8pt;
}

.tableContainer
{
	margin: 10px;
}

.tableCustomContent
{
	display: none;
}

.tableCustomContentMessage
{
	height: 10px;
	vertical-align: middle;
	display: table-cell;
}

.tableRecordContent
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:12px;
	font-weight:bold;
	font-style:normal;
	text-decoration:none;
	color:#999999;
	padding-left:16px;
}

.tableRecordContentWithMultilineText
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	font-size:12px;
	font-weight:bold;
	font-style:normal;
	text-decoration:none;
	color:#999999;
}

.calendar
{
	z-index:4700;
}

input
{
	font-size:10px;
}

select {
	width: inherit;
	height:20px;
	font-size:10px;
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
}

.showPageHelp
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAA2lJREFUOE+FkgtMk2cUhv9LCz+tlNJWNgZKUS4K4hV0GxolGAxKZIQNLxhBU+hk0A6VqejCGioWoxgEohhhLiCdQzsElIsQ8BJoJ1etwCDikLlNR9AVrbW+afa3cSZmJL7JSU7e7z1PvnzfIYlpJHBzpwgnIY9mBDz7MSymF6T12UvTP09t0+XfenxXN8o7OCIgVlGUn17Srs+uHnh84MK9P1MLW26t21WQ5zlvtb89My1EIBRToTHpm5Rn9UM59ePW3ObHULf+jVy2VE1/4VDtmCWl5NZASJQ83tVN9C7EhTeDDIpI3qD8oX/im58fIrvuIQ43PMCR+iForo4gt8Hu/Y69l36D7EzPE7/wxHUMO/P2Jl7+oR8kqOsNu86PQHmJDV/oxcgfk5gwmR1lGByHptaIjJ8eQF4xjJhDNXrPOUskDgCX60QErk2XbTnZ9Tz53DDkP44h/fvbKGvsRmVrLzqNo7C+hu3G3THIyu8gqfxXfF5gMPmuTk3icLgEIRBK6KVbj5V+VtBj3VQ6iG3n7mNH5SgLGoWy6g5q9SN4ZX1ta+waxdbTfUg4NYCNx7qtC77QlLgK2Lfg8CTOIduKdWtyO7EmvxdrTxgRXTKEzaf7Uacftj2dMttau+8jrawLscVGrC/oR6Raj+AthdUcF7ETQTPuXJ/Yo9qFWW0I/VaPT9Q9WKXpw56KPky9eGnrHHxkizve4YCvyuvBpyoDQve3Qxp7pIJ2FnIJxoVPSlZm5vh/1WAJybqOpQc7sTxHD2WZAeNPnqG8ZRgrVL8g7LvbWJzdgQV72+GfdtXiEa7MdmZ4JEHTNMH3jVwxO6nqUWDGNQTvacfCfdcRrboGjbYD8uI2LNln924iaHcbAjKa4ZusHZ8hjVhGUW/WgS/80EkcvjtPKrts8UtrwvyMJihONmNi0mTTtd3FIkUdAhQtmJvWCKmsxuKxMlPFE3iwX/BGNM0hGHGgZGaUutp7h84sTanHx0odvi5qQszBGsxJrYNPyhX47NSZP4o+rHURB4jtM+/IARH5idyWpWR6xpcavRK1k97bL1q8tl80eydWTcxKODMgDpMpGNFc9/8N/0eiKJpg+CKa8VjkI5gfHycKk2eJl3+ZJQyKj2O92QzfnbZn3iuSpAia3TKS4rItl3T0rDed/gU2gZwYU0MTeQAAAABJRU5ErkJggg==);
	width:16px;
	height:16px;
	cursor:pointer;
	float: right;
	background-color: inherit;
}

.showContextualHelp
{
	width:16px;
	cursor:auto;
	float: right;
	margin: 0px 0px 0px 5px;
	opacity: 0.4;
	filter:alpha(opacity=40);
}

.contextualHelpOnHeaderizer
{
	background-color: #949494;
}

.contextualHelpFloatForMisallignedIcons
{
	float: left;
}

.wizardStepContainer{
	display: none;
}

.wizardContainerBorder
{
	border: 1px solid #BABABA;
	padding: 10px;
	overflow: none;
}

.wizardContainerBorderNoPadding
{
	border: 1px solid #BABABA;
	overflow: auto;
}

.wizardContainerNoBorder
{
	padding: 0px 10px 10px 10px;
	overflow: none;
}

/*This is the style for the create email message template page.*/
.createEmailTemplateInfoBar
{
	height:37px;
	margin-top:15px;
	color:#fff;
	background-color:#949494;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAlCAYAAACDKIOpAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB9oJFgsJF1lXLrQAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHUlEQVQI12OIi4v7z2RmZsbAxMDAMESIU6dOMQAAEHoFYm90VxUAAAAASUVORK5CYII=);
	background-repeat:repeat-x;
}

.createEmailTemplateInfoBarLeft
{
	height:37px;
	width:6px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAlCAYAAABh9JjQAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB9oJFgsED/+VyK8AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAArElEQVQoz+3TLY6EUBBF4dOFgvATwgpY3vOsgSXg2QALwaIweEwlGBJEUSN6RvS8tGrbR9aXK+vh7s5v0zQxzzOqysPdXVXp+x53p65rsix7Qtd1lGVJ0zR/Y2QcR0Tk5Qggy7JQVRX/E1WlKIoYzIwkSWLgTV/4wicgItz3HUOe55znGUPbthzHEUMIAVXluq5XSNOUEALrurLvO2YG8PwoAFVlGAa2bcPM+AF4yk9nv5agoQAAAABJRU5ErkJggg==);
	float:left;
}

.createEmailTemplateInfoBarRight
{
	height:37px;
	width:6px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAlCAYAAABh9JjQAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAAd0SU1FB9oJFgsDOAhp+2cAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAt0lEQVQoz+3OsYmEQABA0X8zCyKMimMFTmQFFmIPBhpbg9ZhMSamViHIgIlgMO4Gssctk164P33J/6nr+qm1pixLqqrinSiKAqUU0zTRdR3W2huCICBNU4wxxHFM3/c38KcsyxBCMI7jJwAkScKyLD5EUYS11gcpJc45H353v/CFf4TruhBC+HAcB0opH/Z9J8/zTzjPE2stTdPwAHDOsW0b67rSti1hGPKY5xkpJcYYhmFAaw3AC59xNxEkDKLTAAAAAElFTkSuQmCC);
	float:right;
}

.createEmailTemplateInfoBarTitle{
	padding-top:7px;
	font-size:large;
	float:left;
}

.createDetailsFormContainer{
	border:1px solid #BABABA;
	margin-top:2px;
	padding:10px;
	overflow:inherit;
}

.editDetailsFormContainer{
	border:1px solid #BABABA;
	margin-top:2px;
	padding:10px 10px 22px 10px;
	overflow:inherit;
}

.createElementContainer
{
	border:1px solid #BABABA;
	margin-top:2px;
	padding:10px;
	overflow:inherit;
}

.sendDetailsContainer
{
	min-height:240px;
	border:1px solid #BABABA;
	margin-top:2px;
	padding:10px;
	overflow: auto;
}

.sendDetailsContainerAutoGrow
{
	border:1px solid #BABABA;
	margin-top:2px;
	padding:10px;
	overflow: none;
	min-height:240px;
}

.sendDetailsSelectButtonContainer
{
	float: none;
	height: 28px;
	padding-bottom: 12px;
}

.templateHiddenABRow{
	visibility:hidden;
}

.createDetailsRequiredFormInput{
	font-family: 'Arial';
	color:#0000FF;
	padding-top: 5px;
	width: 4px;
}


.createDetailsFormDesc{
	width:136px;
	font-size:11px;
	font-family:Arial;
	color: #5E5E5E;
}

.createDetailsFormDescData{
	font-size:11px;
	font-family:Arial;
}

.createDetailsFormInput{
	width:560px;
	overflow: visible;
	font-size:11px;
	font-family:Arial;
	color: #5E5E5E;
}

.createEmailTemplateFormRadio{
	width:560px;
	font-size:11px;
	font-family:Arial;
}

.createDetailsFormInputText{
	width: inherit;
	height:21px;
	padding-left: 3px;
	font-size:11px;
	font-family:Arial;
	border: 1px solid #aaaaaa;
	background-color: #ffffff;
	line-height: 21px;
}

.createDetailsFormInputText[readonly]{
	width: inherit;
	height:21px;
	line-height: 21px;
	padding-left: 3px;
	font-size:11px;
	font-family:Arial;
	border: 1px solid #d1d1d1;
	background-color: #f1f1f1;
	cursor: default;
	color: #A0A0A0;
}

.createDetailsFormTextarea{
	width: 100%;
	height: 250px;
}

.createDetailsFormTextareaDisabled{
	width: 100%;
	height: 250px;
	cursor: default;
	color: #A0A0A0;
}

.createDetailsFormSelect{
	width: inherit;
	height:25px;
	line-height: 25px;
	font-size:11px;
	font-family:Arial;
}

.inputDisabled{
	width: inherit;
	height:21px;
	line-height: 21px;
	padding-left: 3px;
	font-size:11px;
	font-family:Arial;
	border: 1px solid #d1d1d1;
	background-color: #f1f1f1;
	cursor: default;
	color: #A0A0A0;
}

.inputDisabledNoDimensions
{
	border           : 1px solid #d1d1d1;
	background-color : #f1f1f1;
	cursor           : default;
	color            : #A0A0A0;
}

.formDropListDisabled{
	width: inherit;
	height:25px;
	line-height: 25px;
	font-size:11px;
	font-family:Arial;
}

.formInputDisabled{
	width: inherit;
	height:21px;
	padding-left: 3px;
	font-size:11px;
	font-family:Arial;
	border: 1px solid #d1d1d1;
	background-color: #f1f1f1;
	cursor: default;
	color: #A0A0A0;
}

.formRadioButton{
	width: inherit;
	height:26px;
	padding-left: 3px;
	font-size:11px;
	font-family:Arial;
	background-color: #ffffff;
	float: left;
}

.formRadioButtonDisabled{
	width: inherit;
	height:26px;
	padding-left: 3px;
	font-size:11px;
	font-family:Arial;
	cursor: default;
	color: #A0A0A0;
	float: left;
}

.formCheckbox{
	height:21px;
	font-size:11px;
	font-family:Arial;
	background-color: #ffffff;
}

.formCheckboxDisabled{
	height:21px;
	font-size:11px;
	font-family:Arial;
	cursor: default;
	color: #A0A0A0;
}

.formText
{
	font-size:11px;
	font-family:Arial;
	color: #5E5E5E;
}

.formTextDisabled{
	font-size:11px;
	font-family:Arial;
	color: #A0A0A0;
	cursor: default;
}

.formFieldText
{
	line-height  : 22px;
	float        : left;
	padding-left : 3px;
}

.templateFormInputABHidden{
	width:100%;
	height:24px;
	font-size:11px;
	font-family:Arial;
}

.createDetailsFormIcons{
	width:25px;
}

.formTableRow
{
	height: 27px;
}

.templateHelpButton{
	display:block;
	cursor:pointer;
	margin-top:3px;
	margin-left:5px;
}

.templateAuthEmailButton{
	float:left;
	display:block;
	cursor:pointer;
	margin-top:3px;
}

.templateABButton{
	float:left;
	display:block;
	cursor:pointer;
	margin-top:3px;
}

.templateDefaultButton{
	float:left;
	display:none;
	cursor:pointer;
	margin-top:3px;
	margin-left:5px;
}


.createSaveBar{
	width: 408px;
	height: 25px;
	margin-top: 7px;
	margin-left: 152px;
	overflow: visible;
}

.createEmailTemplateMsgContainer{
	overflow:auto;
	border:1px solid #BABABA;
	margin-top:2px;
}

.emailTemplateMsgContainerHead{
	height:26px;
	padding: 10px 0px 0px 0px;
}

.messageBodyTabSwitcher{
	margin-top: 4px;
	padding-right: 10px;
	float:left;
	font-size:x-small;
	font-weight:bold;
	height:20px;
}

.messageBodyTabOptionsText{
	float: left;
	margin-top: 2px;
	font-weight: bold;
	font-size: 9pt;
}

#urlMaxLengthValue{
	float: left;
	margin: 0px 0px 0px 3px;
	padding: 1px 0px 1px 2px;
	width: 28px;
	height: 16px;
	font-size: 9pt;
	border-radius: 3px 0px 0px 3px;
	border: 0px;
}

#urlMaxLengthValueCap{
	float: left;
	margin: 0px 3px 0px 0px;
	padding: 1px 0px 1px 2px;
	width: 12px;
	height: 16px;
	font-size: 9pt;
	border-radius: 0px 3px 3px 0px;
	border: 0px;
	background-color: #FFFFFF;
}

#shortenUrlsConfig{
	display: none;
}

.messageBodyTabOptionsInput{
	float: left;
}

.messageBodyTabOptionsImage{
	float: left;
	margin-top: 1px;
}

.divEmailTemplateMsgHTML{
	padding:10px;
}

.emailTemplateMsgContainerNavButtons{
	min-width: 109px;
	height: 26px;
	border-style:solid;
	border-width:1px;
	border-color:#BABABA;
	-moz-border-radius-topleft:5px;
	-moz-border-radius-topright:5px;
	-border-radius-topleft:5px;
	-border-radius-topright:5px;
	border-radius: 5px 5px 0px 0px;
	font-family: Arial, bitstream vera sans,Tahoma;
	font-size: 11px;
	background-color:#E4E4E4;
	color: #515151;
	text-align: center;
	font-weight:normal;
	cursor: pointer;
	float: left;
}

.emailTemplateMsgContainerNavButtonsClicked{
	min-width: 109px;
	height: 26px;
	border-style:solid;
	border-width:1px;
	border-color:#BABABA;
	border-bottom-color: #FFFFFF;
	-moz-border-radius-topleft:5px;
	-moz-border-radius-topright:5px;
	-border-radius-topleft:5px;
	-border-radius-topright:5px;
	border-radius: 5px 5px 0px 0px;
	font-family: Arial, bitstream vera sans,Tahoma;
	font-size: 11px;
	background-color:#ffffff;
	color: #515151;
	text-align: center;
	font-weight:bold;
	cursor: default;
	float: left;
}

.emailTemplateMsgContainerNavButtonsHovered{
	min-width: 109px;
	height: 26px;
	border-style:solid;
	border-width:1px;
	border-color:#BABABA;
	border-bottom-color: #BABABA;
	-moz-border-radius-topleft:5px;
	-moz-border-radius-topright:5px;
	-border-radius-topleft:5px;
	-border-radius-topright:5px;
	border-radius: 5px 5px 0px 0px;
	font-family: Arial, bitstream vera sans,Tahoma;
	font-size: 11px;
	background-color:#ffffff;
	color: #515151;
	text-align: center;
	cursor:pointer;
	float: left;
}

.emailTemplateMsgContainerNavButtonsDisabled{
	min-width: 109px;
	height: 26px;
	border-style:solid;
	border-width:1px;
	border-color:#BABABA;
	-moz-border-radius-topleft:5px;
	-moz-border-radius-topright:5px;
	-border-radius-topleft:5px;
	-border-radius-topright:5px;
	border-radius: 5px 5px 0px 0px;
	font-family: Arial, bitstream vera sans,Tahoma;
	font-size: 11px;
	background-color:#E4E4E4;
	color: #AEAEAE;
	text-align: center;
	font-weight:normal;
	cursor: default;
	float: left;
}

.optionBarLeftButton{
	width:70px;
	height:10px;
	padding:6px;
	margin-top:8px;
	border-right:2px solid #BABABA;
	color:#000066;
	font-size:11px;
	font-family:Arial;
	text-decoration:underline;
	cursor:pointer;
	float: left;
}

.optionBarLeftButton span{
	float:left;
	margin-left:3px;
}

.optionBarLeftButton img{
	margin-top: -2px;
}

.optionBarRightButton{
	width:110px;
	height:10px;
	padding:6px;
	margin-top:8px;
	float:right;
	border-left:2px solid #BABABA;
	color:#000066;
	font-size:11px;
	font-family:Arial;
	text-decoration:underline;
	cursor:pointer;
}

.optionBarRightButton span{
	float:left;
	margin-left:3px;
}

.optionBarRightButton img{
	margin-top: -2px;
}

.divMessageBodyOptions{
	height:35px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAjCAYAAABVcWC0AAAAAXNSR0IArs4c6QAAAAZiS0dEAOQA5ADkLZuEfQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9oKEwg6HT/RliEAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAALklEQVQI16WJMQqAUACF5N3/zNoS8aG2FkGlagD/UTGLpbfm0/JbI6a+RzLjaBdXuT3z3+TICgAAAABJRU5ErkJggg==);
	background-repeat:repeat-x;
	border-bottom:1px solid #C7C7C7;
}

.messageBodyPartHeading
{
	margin: 20px 10px 20px 10px;
	overflow:auto;
}

.messageBodyPartClose
{
	float: left;
	margin-right: 20px;
}

.messageBodyPartName
{
	height: 21px;
	width: 270px;
	font-family:Arial;
	font-size:18px;
	color:#515151;
	border: 1px solid #F2F2F2;
	background-color: #F9F9F9;
	float: left;
	padding: 3px;
}

.messageBodyPartDetail
{
	height: 20px;
	width: 480px;
	font-size: 11px;
	font-weight: bold;
	font-family:Arial;
	color:#909090;
	background-color: #F2F2F2;
	float: right;
	border-radius: 5px;
	padding: 5px;
}

.elementDetailBoldText
{
	color:#515151;
}

.divMessageBodyDetail{
	/*padding:10px;*/
	overflow:auto;
}

.divMessageBodyDetailElement{
	float:left;
	padding:14px;
	padding-bottom: 10px;
}

.divMessageBodyPanelOption{
	float:right;
	padding:17px 20px 10px 20px;
}

.messageBodyDetailElementLabel{
	width:125px;
	font-size:11px;
	font-family:Arial;
}


.messageBodyDetailElementInfo{
	width:450px;
	height:25px;
	font-size:11px;
	font-family:Arial;
}

.divMessageBodyPanelOptionBody{
	width:180px;
	height: 53px;
	padding:0px 10px 0px 10px;
	background-color:#F1F1F1;
	border:1px solid #D1D1D1;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.divMessageBodyPanelOptionHeader{
	height:25px;
	margin-left:5px;
	margin-right:5px;
}

.divMessageBodyPanelOptionHeaderText{
	float:left;
	width:135px;
	font-weight:bold;
}

.messageBodyPanelOptionLabel{
	height:25px;
	width:140px;
	font-size:11px;
	font-family:Arial;
	color: #A0A0A0;
}

.messageBodyPanelOptionInfo{
	width:50px;
	height:25px;
	font-size:11px;
	font-family:Arial;
	text-align:right;
	color: #A0A0A0;
}

.messageBodyDetailHtmlImageManage{
	width:120px;
	color:#000000;
	text-decoration:underline;
	cursor:pointer;
}

.messageBodyDetailHtmlImageManage{
	color:#082DF7;
	text-decoration:underline;
}

.divMessageBodyPreviewWrapper
{
	margin: 0px 32px 20px 0px;
}

.divTemplatePreviewWrapper
{
	margin: 20px 20px 10px 10px;
}

.divPreviewMessage
{
	font-family:Arial, bitstream vera sans,Tahoma, sans-serif;
	color:#797979;
	font-size:8pt;
	margin-left: 1px;
	margin-top: 10px;
}

.divPreviewWrapper
{
	margin: 0px 12px 0px 0px;
}

.divDataPreview
{
	height:300px;
	width: 100%;
	background-color:#F4F4F4;
	border:1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top:2px;
}

.divPreview
{
	height:300px;
	width: 100%;
	background-color:#F4F4F4;
	border:1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top:10px;
}

.divPreviewNotification
{
	font-family: Arial;
	font-size: 13px;
	font-weight: bold;
	background-color:#F4F4F4;
	color: #999999;
	padding: 20px;
	display: none;
}

.divPreviewContent
{
	height:300px;
	white-space: nowrap;
	font-family: monospace;
	background-color:#F4F4F4;
	color: #A0A0A0;
	overflow: auto;
}

.divOnlineElementContentPreview{
	min-height:410px;
	color: #999999;
	background-color:#F4F4F4;
	border:1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin: 0px 0px 12px 0px;
}

.divMessageBodyPreview{
	min-height:410px;
	color: #999999;
	background-color:#F4F4F4;
	border:1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin: 0px 20px 20px 20px;
}

.divTextareaPreview{
	width: 100%;
	min-height:406px;
	color: #999999;
	background-color:#F4F4F4;
	border: none;
	resize: none;
}

.divSendDetailsTemplatePreview
{
	min-height:300px;
	width: 100%;
	background-color:#F4F4F4;
	border:1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.iframeSourcePreview
{
	font-family: Arial;
	font-size: 13px;
	font-weight: bold;
	background-color:#F4F4F4;
	color: #999999;
	padding: 20px;
}

.sourcePreview
{
	font-family: Arial;
	font-size: 13px;
	font-weight: bold;
	background-color:#F4F4F4;
	color: #999999;
	padding: 20px;
}

#confirmLiveSendMessage
{
	width: 550px;
	color: #FF0000;
	font-family: Arial;
	font-size: 13px;
	font-weight: bold;
	padding-top: 20px;
}

#DeletedCommunicationMessage
{
	width: 550px;
	color: #FF0000;
	font-family: Arial;
	font-size: 13px;
	font-weight: bold;
	padding-top: 20px;
}

.validSave
{
	color: #009900;
	font-size:11px;
	font-family:Arial, bitstream vera sans, Tahoma, Verdana, sans-serif;
}

.disabledElementButton
{
	color: #B0ADAD;
	cursor: default;
	text-decoration: none;
}

.disabledIconButton
{
	background-color: #ECEAEA;
	color: #B0ADAD;
	cursor: default;
	text-decoration: none;
}

.enabledElementButton
{
	text-decoration: underline;
}

.bargraphdetails
{
	color: #6b6b6b;
	font-weight: bold;
	font-size: 10px;
	font-family:Arial, bitstream vera sans, Tahoma, Verdana, sans-serif;
}

.calendar
{
	z-index:6000;
}


.limitDropDown
{
	font-family:Arial, bitstream vera sans, sans-serif;
	font-size:11px;
}

.navigationButtonContainer
{
	height:30px;
	margin-top: 10px;
}


.logsDisplay
{
	width:100%;
	height:inherit;
	border:0;
	margin-left:10px;
	font-size:11px;
	resize:none;
	font-family:Arial, bitstream vera sans, Tahoma, Verdana, sans-serif;
}

.temporarilyDisabledMarker
{
	cursor: not-allowed;
}

.breadCrumbContainer
{
	position: absolute;
	top: 60px;
	left: 32px;
	width: 90%;
}

#applicationCrumbs
{
	position: relative;
	top: -1px;
}

#applicationCrumbs .crumbPad
{
	border-bottom: solid 1px #e4e4e4;
	line-height: 15px;
}

#zoneCrumbs .crumbPad
{
	border-top: solid 1px #e4e4e4;
}

.zoneTreeHeader
{
	background-color: #000066;
	padding: 5px;
	color: white;
	font-size: 16pt;
	font-weight: bold;
	vertical-align: middle;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	cursor: move;
	min-width: 300px;
	height: 26px;
	cursor: move;
}

.zoneTreeHeader > span
{
	font-weight: bold;
	font-size: 18px;
	padding-left: 15px;
}

.zoneTreeHeader > div
{
	float: right;
}

.zoneTreeHeader > div > a > img
{
	vertical-align: middle;
}

.zoneTree
{
	float:         left;
	display:       none;
	padding-bottom: 14px;
	border-bottom:    0;
	height:       600px;
	min-width:    310px;
	min-height:   420px;
	font-size:     10px;
	z-index:       4801;
	overflow:    hidden;
}

.zoneTreeBody
{
	cursor: default;
	min-width:300px;
	height:100%;
	position:relative;
	overflow-y:scroll;
	border:1px solid #999999;
	background: white;
	padding-top: 6px;
	cursor: move;
}

#zoneTree_panel
{
	top:           91px;
	left:          32px;
	position:  absolute;
}

#zoneTree_icon
{
	margin-top:     60px;
	margin-left:    26px;
	width:          35px;
	position:   absolute;
	z-index:        4800;
}

#zoneTree_icon img
{
	margin-top:  4px;
	margin-left: 4px;
	border:        0;
}

.errorContainer
{
	border: 1px solid #797979;
	width: 100%
}

.errorContainerMajor
{
	border: 1px solid #BABABA;
	width: 100%
}

.messageDetailsContainers
{
	font-size:11px;
	font-family:Arial;
	color: #5E5E5E;
	border:0;
	width:95%;
	resize: none;
	word-wrap: break-word;
	margin:15px;
	overflow-x: hidden;
}


#toolTip
{
	position:absolute;
	background: #f2f2f2;
	border: 1px solid #868686;
	color: #404040;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 3px;
	padding-right: 3px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top: 0px;
	margin-left:10px;
	font:9px Arial;
	z-index: 10099;
}

.filterToolTip
{
	position:absolute;
	background: #f2f2f2;
	border: 1px solid #868686;
	color: #404040;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 3px;
	padding-right: 3px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top: 0px;
	margin-left:10px;
	font:9px Arial;
	min-width:200px;
	z-index: 100099;
}

.OverViewContent
{
	border: 1px solid #9c9c9c;
	-moz-border-radius: 5px;
	border-radius: 5px;
	padding: 15px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAFsCAIAAACCakTuAAAABGdBTUEAALGPC/xhBQAAABp0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuMTAw9HKhAAAA6UlEQVQ4T+3Q13LCMBBG4X3/V0sjvccU08EUg6lmc0ZmZElRXiCTi2/0n72UXCQqRrN2yXZdtVRirrkb7cpNRINbo+O7pV13qUronpvVVXn4xWNPxfVEe/oqz4EX2hqw8Rp4o42h7532jFQ+Ap+0NWbjK5DQycTXpF2tqUpMm7uRVToRKbd0VuuyPXOVXqBPWwv22YA3NFyquEa0lbMxDkxoY1Wbsl0Zna1/mnGzCpV5xIKbsaksI3Ju+da3oq0dG+uIgluxr23Yoe1BxbWjPUeVfeBAWyX77MjrKuny5DvRlrKh//7qH3wD43UXUJVSTNcAAAAASUVORK5CYII=);
	background-repeat:repeat-x;
	background-color: #ffffff;
	height: 180px;
}

.OverViewContentHeader
{
	border: 1px solid #9c9c9c;
	-moz-border-radius: 5px;
	border-radius: 5px;
	background-repeat:repeat-x;
	background-color: #ffffff;
	height: 28px;
	vertical-align:middle;
	padding-top: 5px;
	padding-left: 15px;
}

.overContentHeading
{
	width: 200px;
	height: 105px;
}

.overContentHeadingLeft
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAABpCAYAAADoZQlKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABp0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuMTAw9HKhAAABBUlEQVRYR+2XMQ5FQBCG9wAKpVIpzqF0CKXSASRKB9ETIVGQOIGIOIZCoRCa/xnJPkHe1q+YTSYSPjuzY2bXLwAIsnVdEccxXNeFrusQQlxGwDiOsCwLnuehKArM83zcvsY5AwFJktwe3CByQTOohqAYyIUSoiCfMTxfOBZBi1MPhuhDc544BVwF3Ai8FxxnHTcCp4CrgBuB/nt5L+AU/GcVaJqGZVnUMslxHNR1rYbCMEQQBGqIxJZpmmjb9re+o44hwDAMRFGEYRhe8Kk3yaZpgu/7sG37rjdJe0pIXruuQ5ZlSNP0ay9o3/dTOSoh6bosS1RVhTzP3+6k223b0Pc9mqbBB11aQbhyzxunAAAAAElFTkSuQmCC);
	background-repeat:no-repeat;
	width: 9px;
}

.overContentHeadingMiddle
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABpCAIAAAB00N7pAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABp0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuMTAw9HKhAAAAG0lEQVQoU2NgAIL///+P4kESBqD4WLduHTIGAD6+MA/9ZN9XAAAAAElFTkSuQmCC);
	background-repeat:repeat-x;
	text-align:center;
	color: #434343;
	line-height: 2.0em;
}

.overContentHeadingRight
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAABpCAYAAAADUrJJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABp0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuMTAw9HKhAAABQUlEQVRYR+2XQcpHUBDA3xHsWMryfw4ncAoLR7B3DhcgkYSyUEpC7mBHWdlIzfeNf3zEhwPMqxd5vzczpnkzbxjHcSDLMui6DsMwwO9gV5PhYhRFoGkaiKIIaZpewrh7G0mSgCAI0HXdCT6AuIomqKr6DDZNA5/PB8qyhGmatg0nicsKY2DbNriuC33ffz/tbVzfEbQsa5me570DgyCAuq4Xn53GXqLjOBDH8TO4mvAokcBbh5N7yD2LB/ankIKCgoKCYitzeByozmxHgjIF5UfKj5QfKT9SVfjLA6+qwjzP7y6aVVWBJEmHELssw9gNK4pyD2ITzvM8mKZ5BtEmVIeSEDIM4wBhS7qoxj9Em1DdlSTsW8MwBLaWh/+eeZ5DURT3YJZl4Ps+jON4Btc2GSUh1Lbt9w5/pRJtQnUoCSGcPwXrcpxusyf8AAAAAElFTkSuQmCC);
	background-repeat:no-repeat;
	width: 10px;
}

.overViewSeparator
{
	border-left:1px solid #9c9c9c;
	height: 170px;
	width: 1px;
}

.overViewContentHeadingContanter
{
	width: 180px;
	vertical-align: top;
}

.overViewContentText
{
	font-size: 11px;
	color: #515151;
}

.overContentHeadingHeader
{
	height: 105px;
}

.overViewContentLinks
{
	color: #000099;
	font-size: 13px;
	padding-left: 1px;
	height: 24px;
}

.hideOverView
{
	height: inherit;
	color: #6B6B6B;
	font-family: Arial;
	font-size: 11px;
	float: right;
	display: inline;
}

.overViewFooterContainer
{
	float: right;
	display: inline;
	margin-top: 8px;
	height: 50px;
}

.overviewContentModal
{
	background-color: white;
	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid grey;
}


/* Send statistics styles */
.statisticsSummaryHeading
{
	background-color:#dfefff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	margin-bottom: 5px;
}

.statisticsSummaryHeadingDetails
{
	font-size: 11px;
	color: #515151;
}

.statisticsSummaryHeadings
{
	font-size: 16px;
	font-weight:bold;
	color: #515151;
}

.graphStatsHeading
{
	font-size: 14px;
	color: #515151;
	width: 50%;
}


.graphStatsHeadingSmall
{
	font-size: 12px;
	color: #515151;
	width: 50%;
}

.graphStatsHeadingDisabled
{
	font-size: 14px;
	color: #C9C9C9;
}

.graphStatsSecondHeading
{
	font-size: 11px;
	color: #868686;
	vertical-align : top;
}

.graphStatsSecondHeadingDisabled
{
	font-size: 11px;
	color: #C9C9C9;
	vertical-align : top;
}

.statisticsInterDataYellowBlock
{
	background-color:#FFFFCC;
	border: 1px solid #b5b5b5;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	height:40px;
	font-weight:bold;
}

.statisticsInterDataWhiteBlock
{
	background-color:#FFFFFF;
	border: 1px solid #b5b5b5;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	height:40px;
	padding-left: 5px;
	padding-right: 5px;
}


.statisticsDataHeading
{
	background-color:#D6D6D6;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	height:30px;
	font-size: 15px;
	font-weight:bold;
	color: #515151;
}

.statisticsDataHeadingSmall
{
	background-color:#D6D6D6;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	height:10px;
	font-size: 13px;
	font-weight:bold;
	color: #515151;
}

.statisticsDataContent
{
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	padding: 5px;
	font-size: 14px;
	border: 1px solid #b5b5b5;
}

.sendStatisticsFooter td
{
	font-size: 11px;
	text-align: center;
	color: #868686;
	padding: 0;
}

.sendStatisticsSentDataLeft
{
	background-color: #e4e4e4;
	-webkit-border-top-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	border-top-left-radius: 5px;
	padding-left: 8px;
	padding-top: 4px;
	height: 20px;
}

.sendStatisticsSentDataRight
{
	background-color: #e4e4e4;
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	border-top-right-radius: 5px;
	padding-left: 8px;
	padding-top: 4px;
	height: 20px;
}

.sendStatisticsSentDataHeading
{
	background-color: #e4e4e4;
	padding-left: 8px;
	padding-top: 4px;
	height: 20px;
}


.sendStatisticsSentDataInformation
{
	background-color: #e4e4e4;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-left-radius: 5px;
	padding-left: 8px;
	font-size: 11px;
	height: 20px;
	padding-bottom: 4px;
}

.sendStatisticsSentDataContent
{
	background-color: #e4e4e4;
	padding-left: 8px;
	font-size: 11px;
	height: 20px;
	padding-bottom: 4px;
}

.sendStatisticsSentDataNormal
{
	padding-left: 8px;
	font-size: 11px;
	height: 20px;
	padding-bottom: 4px;
}

.sendStatisticsSentDataNormalHeading
{
	padding-left: 8px;
	padding-top: 4px;
	height: 20px;
}

.sendStatisticsSentDataNormalHeadingSmall
{
	padding-left: 8px;
	padding-top: 4px;
	height: 10px;
}

.sendStatisticsSentDataContentEnd
{
	background-color: #e4e4e4;
	border-bottom-left-radius: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-right-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	border-radius: 0px 0px 5px 5px;
	padding-left: 8px;
	font-size: 11px;
	height: 20px;
	padding-bottom: 4px;
}

.sendStatisticsAdditionalData
{
	height: 20px;
	background-color: #ffffcc;
	padding-left: 8px;
}

.statsPopup
{
	display:none;
	visibility:hidden;
	background-color:#f2f2f2;
	font-size: 11px;
}

.pageTransitionStepUnselectedFront
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAABhCAIAAACGSmw3AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAANtJREFUWEftmDELgzAQhe///5iCDuomODqKCg5ubiEuUUEU2helUrAN1/3dmu/Oy+NdJJHnEeu6Nk1TFEUcx4/fIUCNMVmW1XVtrd227cz/GoKqQMEFoGtJ0ACqalAwgl6VhT2NbYV7/fysQABlG7426ZtY1OTuH2pCTTSnCn1Cn9AnbwWkbVuNGifD2eHsaNxCn9An9Ml1xvIGwxuMYhz+PDWjKNr3XVH3+HPneT6Oo5auqqrrOi09z3OSJM45TYJ/4xiGAQl93y/LEs7xNGKaprIs0zQNPJ5g6QUDPYtlebeYGgAAAABJRU5ErkJggg==);
	width: 5px;
	height: 97px;
	position:relative;
	background-repeat: no-repeat;
	width: 1%;
}

.pageTransitionStepUnselectedContent
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABhCAIAAACYg1yEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAABdJREFUKFNjWLduHcP///9H8QCFATD8AUd4IAfLE6sRAAAAAElFTkSuQmCC);
	height: 97px;
	font-size: 9px;
	padding-top: 4px;
	color: #868686;
	padding-top: 12px;
	width: 19%;
	background-repeat: repeat-x;
}

.pageTransitionStepUnselectedMiddle
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAABhCAIAAAASrMXFAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAABJJJREFUaEPNm1lPIkEURv3/P2ZETZCoUSES0KhxAYkYWVzjvhvEZeYklenp0N21oNP39gPhoeg6fLeq+m498evvNTk5OTs7Oz8/X6lU6vX6+vr6/v7+9fX15+fn7/9wvb29NZtN5pqeno4YUr9MRLN/fX29vLw8PT1dXV1dXFz0er1Go7G8vDwzM7OystLtdn+QkylKpRL3v7m5eX9/t9/5H2LWuOFweHJyAuvCwsL5+fn3QdEPPuA8b+VGjG50enrKMlhdXf2m6bEv+nnyMSwAkdEsBpZprVZDWv85Rkay/vwlDEY0lJubm0wztpbsD+f6i/+rMBXNL6FcWlpiv48nJNs26IfjIDIBG5+dfn9/HzSZGZwTIjMdHBywx8cwd36IULIi2Z6hQuaK+Pr6irl5AgVR5ooIGU+dubm5oB2aNyKU1Wp1Z2fHX0gBRB5omPvy8tKTUgARsuPjY7wkz0eODCKUa2trGxsbPkKKISJhsVg8OztzUoohQgYflIPBwE4piQjZ1tYWrpBqRA5I9g27x0IprCJkOP2cQcQYWZTyiJBxknOeq0b8+PjgqXh4eJhKqUJFyG5vb/Gun5+fk5RaECEzYbJqROIHfN5k/KBIRfR7eHjA3HzGtdSFCBkqLi4uxuMHdYiYm0xGPH5Qh4iQHOOY++7uzphbIyJYhItR/KAUEUrSayZ+0ItowkXiB72I6Ee4iB9UKBScbm98wJgJk6A54oPxJsvlctDP80Y04aJP/BD9jbwRmfjo6Ij4AVZPLQUQISPXT7ZXNaIJF+3xg6Shzdxk9lmUnEROLWUMbbAIFy3xg7yKEJhw0VnRkVQRSkoHWfGDChUNBA/u1PhBESLhIhWndrudtW+EDW2wqDwk4wdFKhoU3HJqOan1BxUqgkj8QIiTWn/QggglxzgrEtaRRakFETLccg7z5KbRgmiCm9RsuQpEUj/s6KwSkzwiu5hAe3d3V++5SLrCXu4UVpF0z9TUVJQFSBVSEhETp6bOFB06lidKnFJMReOGjeT1FBkaZ5ZTkLMwaxfLq+j0EYURW62WvTAjvF1MeOqMV8RUxFfAUcDp8lmCMi4tqRxMzKUUkSSOyUAozS9iYsr+XOinFBHxomyYRkTq/Ky/qD1THSImpt8x7vGrQzT57bjHrwuRohAmps4fP2UUIWJikjXJXi1FiDgyJGuSHW9aEB8fH+N1P3WGxuMnQZPVfalCxWQNWpeKhHN2j19YRZ+gThgxq6tEi6FJyjhz65KejgnqOp2O010VM7S9T0ze0NTqMbGl204YES/G2bMoiYivQJevs/NTEtGzf1YM0QR1QV0EuR465iUnz15uGRWDOuIFEKO+IOdBnRyQx9GNianGb29vj8GXx1rElyHiTPX4PYnJv1Pg9RzMsLBEMu4+eS36VzxfykjlwCGnGfjnETFuv9/nKeeZ/bUQ8DQPekHPrSJwvJNK9YEr6C3ILEoe5ZymvKbnKeQE9beRCyOat3whw0WFDNPw3fOOPsO4G5T0pnv16eABjFz8mHOBT3Ixe3t7I7kEHwKfMehCLo+pmct+/QEk00AejPP3mgAAAABJRU5ErkJggg==);
	width: 54px;
	height: 97px;
	background-repeat: no-repeat;
}

.pageTransitionStepUnselectedEnd
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAABhCAIAAAD5m37GAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAABA9JREFUaEPNm2tLKlEUhv3/P+ZECQUmJaFkkZFlgYma2IXu3oqudh7YEKIztmdf13yIPozMw7tm773etdbk/iVdKysrGxsbm5ubOzs71Wr14OCg2Ww+PDx8f3//hL1yiY+bTqcvLy/j8fj+/v729vbi4qLRaJRKpXw+Xy6Xu91uMMhkvrTHf3x8XF5eAlosFm9ubgJQZuP7Bbq6uiL6e3t7viNuyAco7wCv5u7uLqL6E9KcTyEeHh6yhvypaMWnELe3t1ndniS05QOLZc66HgwGPhAd8IHVarVY0T6i7IYPRN7C09NT5xI643t9fSXKnDFuEZ3xgcW5UigUPj8/HSK65AOrUqkcHx/L5Xt/fyfKd3d3rhAd6wdWv98n93F1qLjnA3F/f79WqzmR0Asf4q2vr19fX9sjeuEDCzgQ397eLBF98YF1dHREgiOXj42QhcJysUH0qB9Y2AO2G6yCMaJfPrDYrtm05fJ9fX1x6LXbbTNE7/qB9fT0tLa2NplMDBBD8IFF6kUCJpcPG0ACa2ADAumHcsPhkCjzN5OK4fjAQr+tra1MNiAoH1Gm9pDJBgTlQ0L2aqL8/PysGeXQfGBh9vRtQAQ+ECmCadqAOHzK7OnYgDh8yuzp2IBofCCSHVJeWr5QYvIps7fcBsTkU2YPGwBomoqR+cCi+E4dVi6fMntpNiC+fihHqZ0XkU1nUUURfMrsJdoAKXzK7C12VqTwIeHj4+OiDRDEp8zenA2QxYfZo+1zfn7+u1Bk8YFFH2DWBojjU2aPnoqyARL5sAHYFGUDJPKBxV7NWwioRD6wSLDZsYXqpwyKqmCL048yDev3t88ji481i0E+OTkRuv9RYJjrMwrSj9LM6urqnHWXwkdkEwtcUvhmz4zZLFUEn8qsEktv8fnITNnt2PMSLVJ8vsWcT1B8KcEsb5DE1I8TLNFziNCPJCDNs4ngo+xCZP9scMaJLwWXJTWDyPoRWRrsXDol6Aj6/VmziqkfLxyvnf5sY1D9iCzzgipx17yC8mnWnOPEl84MkaWjrqmcui2QfkSWwopmzyOCfqQnFFYMRsdC6DcajTL13ILqR+JOMSVTzzIon0HPNxwfZsygZx6IL82SSdlfjGc2QuhHAcV45sU7n7JknU4nUyjD+TfLmSu/+mHJiKzNzJpHPmXJLGf+fPGRBDAWaz8z6YvP1cypFz5lyZzM7LrnU9/6uJp5ds/ndmbcMZ/+sI3Bdm2bnxJZ+t71et3g2To/seIjQ8EsmiXuOnBW/oisnS4eoyGuPlVwdv4S016vxyGWVpTV1EbntmzxhYzvL2kEcFHU1nmA5T05+l1LLmKnvmUFi3wTLMwO/1s+Vf/nOY7zJRdHAt8H85e6ydnZWVb3r8+Rdud/5npvGqALBLIAAAAASUVORK5CYII=);
	width: 55px;
	height: 97px;
	float: left;
	z-index: 101;
	position:relative;
	background-repeat:no-repeat;
}

.pageTransitionStepHeader
{
	font-size: 12px;
	font-weight: bold;
}

#messageBodyToTrack
{
	font-family:Arial, bitstream vera sans, Tahoma, Verdana, sans-serif;
	font-size: 10px;
	font-weight: bold;
	color:#333333;
	float: left;
	padding-top: 9px;
}

.imagePreviewError
{
	background-color: #FFFFCC;
	border: 1px solid #B5B5B5;
	border-radius: 5px;
	font-weight: bold;
	font-family:Arial;
	color: #5E5E5E;
	font-size: 11px;
	margin: 20px 10px 20px 10px;
	padding: 10px 5px 10px 5px;
	overflow: auto;
}

.imagePreviewImageNameHeading
{
	background-color: #FFFFFF;
	border: 1px solid #B5B5B5;
	border-radius: 5px;
	padding: 3px 6px;

	font-size: 11px;
	color: #5E5E5E;
}

.imagePreviewErrorImage
{
	float: left;
	width: 24px;
	text-align: middle;
	height: auto;
}

.imagePreviewErrorMessage
{
	float: left;
	text-align: middle;
	height: auto;
	clear: bottom;
	display: inline
}

.imageJsonWarning
{
	width:95%;
	height: 26px;
	background-color: #F2F2F2;
	font-family:Arial;
	font-size:11px;
	font-style:normal;
	text-decoration:none;
	text-align: left;
	color:#5E5E5E;
	border-radius: 5px;
	padding-left: 10px;
	padding-top: 11px;
	border: 1px solid #b5b5b5;
	margin-top : 25px
}

.tableDataWarning
{
	width:95%;
	height: 26px;
	background-color: #F2F2F2;
	font-family:Arial;
	font-size:11px;
	font-style:normal;
	text-decoration:none;
	text-align: left;
	color:#999999;
	border-radius: 5px;
	padding-left: 10px;
	padding-top: 11px;
	border: 1px solid #b5b5b5;
	position:relative;
	top: 20%;
}


.imageJsonError
{
	width:95%;
	height: 36px;
	background-color: #F2F2F2;
	font-family:Arial;
	font-size:11px;
	font-style:normal;
	text-decoration:none;
	text-align: left;
	color:#999999;
	border-radius: 5px;
	padding-left: 10px;
	padding-top: 11px;
	border: 1px solid #b5b5b5;
	position:relative;
	top: 20%;
}

.jsonImageBlock
{
	height:95%;
	margin-top:-3px;
	width: 99%;
	margin-left:-4px;
}


.ImagePreviewContent
{
	font-family:Calibri;
	font-size:15px;
	font-weight:normal;
	font-style:normal;
	text-decoration:none;
	color:#797979;
}

.popupPreviewLoading
{
	text-align:center;
}

.popupPreviewLoadSuccess
{
	background-color: #EAF6F4;
	height: 20px;
	padding-top: 6px;
}

.popupPreviewLoadError
{
	background-color: #FFFECB;
	height: 20px;
	padding-top: 6px;
}

.elementPreviewModalFrame
{
	color: #999999;
	background-color:#FFFFFF;
	border:1px solid #B5B5B5;
	margin: 30px 10px 20px 20px;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.embeddedHtmlPreviewFrame
{
	color: #999999;
	background-color:#F4F4F4;
	border:1px solid #929292;
	margin: 0px 20px 20px 20px;
	padding: 5px 5px 0px 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.elementPreviewModalRenderer
{
	width: 900px;
	height: 410px;
	border:0px solid #929292;
	background-color:#FFFFFF;
}

.elementPreviewModalDetailsSection
{
	background-color: #FFFFFF;
	border: 1px solid #B5B5B5;
	border-radius: 5px;
	padding: 3px 6px;

	font-size: 11px;
	color: #5E5E5E;
}

.htmlElementPreviewRenderContainer
{
	color: #999999;
	background-color:#F4F4F4;
	border:1px solid #929292;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.htmlPreviewSelectorIconUnderline
{
	width: 20px;
	position: relative;
	top: -1px;
	margin-left: auto;
	margin-right: auto;
	border-bottom-color: #476E93;
	border-bottom-style: solid;
	border-bottom-width: 0px;
}

.htmlElementSourceCodePreview
{
	margin: 0px 0px 5px 0px;
	width: 100%;
	height: 410px;
	background-color:#F4F4F4;
	border: none;
	resize: none;
}

.htmlElementSourceCodePreviewText
{
	color: #999999;
	background-color:#F4F4F4;
	resize: none;
}

.textElementPreview
{
	width: 900px;
	height: 405px;
	color: #999999;
	background-color:#F4F4F4;
	border: none;
	resize: none;
}

.imageElementPreview
{
	width: 100%;
	min-width: 400px;
	color: #999999;
	border: none;
	resize: none;
	margin: 0px 0px 5px 0px;
}

.htmlElementPreview
{
	width: 900px;
}

.htmlElementPreviewSelector
{
	width: 30px;
	height: 25px;
	text-align: center;
	cursor: pointer;
	display: block;
	margin: 0 auto;
	float : right;
}

.htmlElementPreviewTabbedSelector
{
	border: 1px solid #929292;
	-moz-border-radius-topleft:5px;
	-moz-border-radius-topright:5px;
	-border-radius-topleft:5px;
	-border-radius-topright:5px;
	border-radius: 5px 5px 0px 0px;
	background-color:#F4F4F4;
}

.htmlElementPreviewSelectorEnabled
{
	border-bottom-color: #f4f4f4;
}

.htmlElementPreviewSelectorDisabled
{
	border-bottom-color: #929292;
}

#htmlContentPreview
{
	margin-top :20px;
}

.elementPreviewErrorFrame
{
	width: 100%;
	height: 405px;
	font-size: 12px;
	color: #999999;
	background-color:#F4F4F4;
	border: none;
	resize: none;

}

.embeddedHtmlPreviewer
{
	margin: 0px 12px 0px 0px;
	min-height:410px;
	width: 100%;
	background-color:#FFFFFF;
	border:0px solid #929292;
}

.previewFrame
{
	color: #999999;
	background-color:#F4F4F4;
	border:1px solid #929292;
	min-height: 300px;
	margin: 30px 18px 25px 20px;
	padding: 5px 5px 5px 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.readonlyOverlay
{
	position            : fixed;
	height              : 100%;
	width               : 100%;
	top                 : 0px;
	right               : 0px;
	bottom              : 0px;
	left                : 0px;
	z-index             : 4800;
	filter              : drop-shadow(2px 4px 10px black);
	opacity             : 0.15;
	background-image    : none;
	background-position : center;
	background-repeat   : no-repeat;
	background-size     : 45vw;
	pointer-events      : none;
}

.backgroundTopRightMenuEnd
{
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAaCAYAAAEvRifVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAkNJREFUeNpidAytfbH/now4AxAwgggG45n/GVAAUIAJxjZXeKYPEECMyEpQ9ICVMTP+Q9XPZTH1P0AA4TAaqoXhbDpjmN7tH8gSjOgqYboBAogRqzG28k9q0QXBRmi7t/y5+kaUGdNcJGPg/rOSf+aN1QUu4dU/99yRY8NwlWNI7W+AAAKrVHVp/3P7vRAzAwEAtuv2nkqWCN1bv4lSDAIrFvSyWck9TsWnmBGboLl/08+TT8TZcJqMDE5urGMP1r79hSiT4TYE1+tdfip28dtvFjAfIIAY2c2n/f/5h2BAMDC5KT98zkAEAFvvF1P+a9N1JVaCwfX6G48qL/svwuF6fF3tQ1uFZ7eJDtPQhJLfqy+rsuCNKRC49Z5bVITrOwNBhRc3Nn4wlHx1nOjojEgs/rPikhozUbEj7dj3/+knbvxxDk67Qm8XEB3f0UmFf5Ze1GAmqBAEguNKPp97JsYDEGCM9sF1UzlYf6fsvK3AxkAlwAhLfoq8X85suqHECkuCFBsKA95RFQ/vvuGVvvFWhJkSQ1FCfuuyDnlRvh8lgVp3/lDNpTCg718voCX0+eUOYDi//85OHUNhwD2i6szrL+z6555LsJDtfXSwc0WbCQfb/5hQndt/MCoPcl2KDMITSr4duC/F+fILN/UMBQGnkJotv/8xuR5+KM1GtvfRwb41LT6/fjE6Rxvc+IsvOBjJTTZBsWXvzzwTE3j0gZd6hoJzok+9jaLIl33A4GBFLnYYqZXfnUKqlvFx/nEV5PgpDACmLr5Cf0YitgAAAABJRU5ErkJggg==);
	min-width : 20px;
	border: 0px;
	padding: 0px;
	margin: 0px;
}

.backgroundTopRightMenu
{
	border: 0px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAaCAIAAAGOYZEQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACdJREFUeNpiYjCeycTAwADDAAHEhMQHCCAmDDkIBggw3OI4cLbFJQAELgTaztfD0AAAAABJRU5ErkJggg==);
	background-repeat:repeat-x;
	font-family:Arial, bitstream vera sans,Tahoma;
	font-size:10px;
	color:#FFFFFF;
	height: 26px;
	padding-right:10px;
}

.mainMenuLink
{
	font-family:Arial, bitstream vera sans;
	font-size:10px;
	color:#FFFFFF;
	font-weight:bold;
	font-style:normal;
	text-decoration:underline;
}

.mainMenuLink:hover
{
	font-family:Arial, bitstream vera sans;
	font-size:10px;
	color:#FFFFFF;
	font-weight:bold;
	text-decoration:none;
}

.currentApp
{
	font-family:Arial, bitstream vera sans;
	font-size:10px;
	color:#ffcc99;
	font-weight:bold;
	text-decoration:underline;
}

.loggedInUserMenu
{
	font-family:Arial, bitstream vera sans,Tahoma;
	font-size:10px;
	color:darkgrey;
	padding-right:5px;
}

.loginModalButtons
{
	margin-left:118px;
}

.loginModalMessage
{
	font-family:     Arial;
	font-size:       11px;
	font-weight:     normal;
	font-style:      normal;
	text-decoration: none;
	color:           #FF0000;
	text-align:      center;
	padding-bottom:  10px;
}

.loginFormForgottenPassword
{
	margin-top:     5px;
	font-family:    Arial;
	font-size:      11px;
	color:          #A1A1A1;
	cursor:         pointer;
}

.loginFormSwitchUser
{
	margin-left:    118px;
	font-family:    Arial;
	font-size:      11px;
	color:          #A1A1A1;
	cursor:         pointer;
}

