.striataFooterRight
{
	font-family:    Arial, bitstream vera sans, Tahoma, sans-serif;
	font-size:      11px;
	color:          #868686;
	font-weight:    normal;
	margin-right:   32px;
	margin-top:     12px;
	margin-bottom:  12px;
	text-align:     right;
	float:          right;
}

.striataFooterLeft
{
	font-family:     Arial, bitstream vera sans, Tahoma, sans-serif;
	font-size:       11px;
	color:           #868686;
	font-weight:     normal;
	margin-right:    32px;
	margin-top:      12px;
	padding-left:    32px;
	margin-bottom:   16px;
	text-align:      left;
	float:           left;
}

