
/* Styles the highlighted glossary text */
.glossaryText
{
	color: purple;
	cursor: pointer;
	border-bottom: 1px dotted;
}


/* Styles for the glossary-specific tool tip */
#glossaryTip
{
	position:absolute;
	background: #f2f2f2;
	border: 1px solid #868686;
	color: #404040;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 3px;
	padding-right: 3px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin-top: 0px;
	margin-left:10px;
	font:9px Arial;
	z-index: 10099;
}
